import request from '@/utils/service';

export function getAllContract(params) {
  return request({
    url: `/esignContract/getAllContract`,
    method: 'post',
    data: params,
  });
}

export function signRedirect(params) {
  return request({
    url: `/esign/signRedirect/${params}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function certifyByEsign(params) {
  return request({
    url: `/custRealInfo/certifyByEsign`,
    method: 'post',
    data: params,
  });
}
