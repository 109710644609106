const state = {
  linkage : {a:133455,b:1234}
};

const mutations = {
  setActiveTab (state, data) {
    state.linkage = data;
  }
};

const actions = {
  checkout({ commit, state }, products) {
    commit('setActiveTab', products);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
