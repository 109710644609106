import { loginByCode } from '@/api/user';
import { selectByCustRealId } from '@/api/home';
import { formatTime } from '@/utils/tools';
import {
  // saveToken,
  saveLoginStatus,
  saveUserInfo,
  removeToken,
  removeUserInfo,
  removeLoginStatus,
  loadLoginStatus,
  loadToken,
  loadUserInfo,
  getSendStatus,
  saveSendStatus,
  loadHome,
  getHomeInfo,
  loadBusinessNews,
  getBusinessNews,
  loadPathUrl,
  getPathUrl
} from '@/utils/cache';

const state = {
  loginStatus: loadLoginStatus(), // 登录状态
  token: loadToken(), // token
  userInfo: loadUserInfo(), // 用户登录信息
  homeInfo: getHomeInfo(),
  businessNews: getBusinessNews(),
  sendStatus: getSendStatus(),
  pathUrl: getPathUrl()
};

const mutations = {
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_LOGIN_STATUS: (state, loginStatus) => {
    state.loginStatus = loginStatus;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_HOMEINFO: (state, info) => {
    state.homeInfo = loadHome(info);
  },
  SET_SENDSTATUS: (state) => {
    state.sendStatus = saveSendStatus(info);
  },
  SET_BUSINESSNEWS: (state, info) => {
    state.businessNews = loadBusinessNews(info);
  },
  SET_PATHURL: (state, info) => {
    state.pathUrl = loadPathUrl(info);
  }
};

const actions = {
  // 登录相关，通过code获取token和用户信息，用户根据自己的需求对接后台
  loginWechatAuth({ commit }, code) {
    const data = {
      code: code
    };
    return new Promise((resolve, reject) => {
      loginByCode(data)
        .then((res) => {
          // let resultData = res.resultData;
          console.log(res);
          // 存用户信息，token
          commit('SET_USERINFO', saveUserInfo(res.resultData));
          // commit('SET_TOKEN', saveToken(resultData.tokenResult.accessToken));
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取首页信息：
  getHomeInfo({ commit }, params) {
    return new Promise((resolve, reject) => {
      selectByCustRealId(params)
        .then((res) => {
          if (res.status === 'complete') {
            let resultData = res.resultData;
            resultData.finalPayTime = formatTime(resultData.finalPayTime);
            commit('SET_HOMEINFO', resultData);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 设置状态
  setLoginStatus({ commit }, query) {
    if (query === 0 || query === 1) {
      // 上线打开注释，本地调试注释掉，保持信息最新
      removeToken();
      removeUserInfo();
    }
    // 设置不同的登录状态
    commit('SET_LOGIN_STATUS', saveLoginStatus(query));
  },
  // 登出
  Logout() {
    // 删除token，用户信息，登陆状态
    removeToken();
    removeUserInfo();
    removeLoginStatus();
  },
  setSendStatus({ commit }, status) {
    commit('SET_SENDSTATUS', status);
  },
  setBusinessNews({ commit }, status) {
    commit('SET_BUSINESSNEWS', status);
  },
  // 保存url
  savePathUrl({ commit }, data) {
    commit('SET_PATHURL', data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
