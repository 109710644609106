let apiconfig = window.apiconfig || {};
let env = process.env;

let serverconfig = {
  WECHAT_APPID: '',
  WECHAT_AUTH_URL: '',
  API: '',
  AK: '',
  PARENT_REGION_ID: '',
  CUSTOMRT_TEL: '',
};
for (let key in serverconfig) {
  if (Object.prototype.hasOwnProperty.call(serverconfig, key)) {
    serverconfig[key] = apiconfig[key] || env[`VUE_APP_${key}`];
  }
}

export default serverconfig;
