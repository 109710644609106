/*
 * @Author: yangliao
 * @Date: 2020-11-16 17:39:23
 * @LastEditTime: 2020-11-27 09:06:56
 * @LastEditors: yangliao
 * @Description: 业务组件相关接口
 * @FilePath: /netHallOfficialAccounts/src/api/components.js
 */

import request from '@/utils/service';


/* 词语 */
export function getAllDict(id) {
  return request({
    url: `/sysDictListByCode/${id}`,
    method: 'get'
  })
}

/* 行政区域 */
export function selectRegionTree(parentRegionId) {
  return request({
    url: `/nethallRegion/tree/${parentRegionId}`,
    method: 'get',
  })
}

/* 附件上传 */
export function uploadPicture(data) {
  return request({
    url: '/minioPicture/uploadPictureBase64',
    method: 'post',
    data,
    hideloading: true
  })
}

/* 根据图片ID获取URL */
export function getPictureUrl(data) {
  return request({
    url: '/minioPicture/getPictureUrl',
    method: 'post',
    data
  })
}

/* 根据图片ID获取URL */
export function getPictureData(data) {
  return request({
    url: '/minioPicture/viewFiles',
    method: 'post',
    data,
  });
}
