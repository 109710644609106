/*
 * @Author: your name
 * @Date: 2020-09-25 08:26:30
 * @LastEditTime: 2021-07-14 15:37:35
 * @LastEditors: yangliao
 * @Description: In User Settings Edit
 * @FilePath: /netHallOfficialAccounts/src/core/lazy_use.js
 */
import Vue from 'vue';

import {
  Button,
  Icon,
  Grid,
  GridItem,
  Swipe,
  SwipeItem,
  Cell,
  Tag,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Col,
  Row,
  Field,
  Image,
  List,
  Divider,
  Form,
  CellGroup,
  Popup,
  NumberKeyboard,
  Overlay,
  Loading,
  Notify,
  Tab,
  Tabs,
  Switch,
  DropdownMenu,
  DropdownItem,
  Uploader,
  Dialog,
  Picker,
  Slider,
  Stepper,
  Calendar,
  TreeSelect,
  PullRefresh,
  ImagePreview,
  Step,
  Steps,
  DatetimePicker,
  NoticeBar,
  ActionSheet,
  ShareSheet
} from 'vant';

Vue.use(Button);
Vue.use(Icon);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Cell);
Vue.use(Tag);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Col);
Vue.use(Row);
Vue.use(Field);
Vue.use(Image);
Vue.use(List);
Vue.use(Divider);
Vue.use(Form);
Vue.use(CellGroup);
Vue.use(Popup);
Vue.use(NumberKeyboard);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Notify);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Switch);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Uploader);
Vue.use(Dialog);
Vue.use(Picker);
Vue.use(Slider);
Vue.use(Stepper);
Vue.use(Calendar);
Vue.use(TreeSelect);
Vue.use(PullRefresh);
Vue.use(ImagePreview);
Vue.use(Step);
Vue.use(Steps);
Vue.use(DatetimePicker);
Vue.use(NoticeBar);
Vue.use(ActionSheet);
Vue.use(ShareSheet);
