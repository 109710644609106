<!--
 * @Author: your name
 * @Date: 2020-09-25 08:26:30
 * @LastEditTime: 2021-07-13 17:33:56
 * @LastEditors: yangliao
 * @Description: In User Settings Edit
 * @FilePath: /netHallOfficialAccounts/src/App.vue
-->
<template>
  <div id="app">
    <!-- <div v-wechat-title="$route.meta.title"></div> -->
    <router-view />
  </div>
</template>
<script>
import store from '@/store';
export default {
  data(){
    return {
      configFlag:false
    }
  },
  computed:{
    token(){
      return store.getters.userInfo?store.getters.userInfo.token : null;
    }
  },
  watch:{
    token:{
      handler(value,old){
          if(value&&!this.configFlag){
            let href = window.location.href;
            this.$getWxApplets(href);
          }
      },
      immediate:true
    }
  },
  mounted(){
    this.configFlag=false;
  }
}
</script>
<style>
html,
body {
  height: 100vh;
  margin: 0;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
}
html {
  /* overflow-y: scroll; */
}
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  background-color: #f6f6f6;
  -webkit-font-smoothing: antialiased;
}
/* 弹出确认框字体调整 */
.van-dialog__cancel,
.van-dialog__confirm {
  font-size: 14px;
}
</style>
