import qs from 'qs';
import request from '@/utils/service';

/**
 * 登录接口 获取用户信息
 * @param params {code: code}
 */
export function loginByCode(params) {
  return request({
    url: `/wxSecurity/${params.code.appId}/loginByCode/${params.code.wxCode}`, // 网厅
    // url: `/wxmisweb/reportWx/newspaper/getWxOpenId/${params.code}`, // 魏县报装版本
    // url: '/cdwthirdwxpayapi/openid/wxOauth2token', // 原始版本
    method: 'get',
    // params
  });
}

export function createJsApiSignature(params) {
  return request({
    url: `/wxSecurity/${params.appId}/createJsApiSignature`,
    method: 'post',
    data: params.pageUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

/**
 * 获取微信 js sdk权限
 */

export function jssdkAuto(params) {
  return request({
    url: '/cdwthirdwxpayapi/jsSdk/config',
    method: 'get',
    params,
  });
}

/**
 * 查询用水户信息
 * @param {*} params
 */

export function getWaterUserAddress(params) {
  return request({
    url: '/cdwwechatapi/wechatPublic/getWaterAcountAddress',
    method: 'get',
    params,
  });
}

/**
 * 绑定用水户
 * */

export function bingWaterAcount(params) {
  return request({
    url: '/cdwwechatapi/wechatPublic/bingWaterAcount',
    method: 'post',
    data: params,
  });
}

/**
 * 查询当前会员下绑定用水户
 */
export function queryBindUsers(params) {
  return request({
    url: '/cdwwechatapi/wechatPublic/getWaterAcountByCustomer',
    method: 'get',
    params,
  });
}

/**
 * 统一下单
 * @param {*} params
 */
export function wxJsPay(params) {
  return request({
    url: '/cdwthirdwxpayapi/unifiedOrder/wxJsPay',
    method: 'post',
    data: params,
  });
}

// export function loginByCode(params) {
//   return request({
//     url: '/wechat/auth2',
//     method: 'post',
//     data: qs.stringify(params)
//   });
// }
/**
 * 获取登录用户信息
 * @param params
 */
export function getUserInfo(params) {
  return request({
    url: '/user/get_user',
    method: 'post',
    data: qs.stringify(params),
  });
}

/**
 * 获取当前租户下所有商品
 */

export function allProduct() {
  return request({
    url: '/cdwwechatapi/wechatPublic/allProduct',
    method: 'get',
  });
}

/**
 * 下单生成预付单
 */

export function paymentOrder(data) {
  return request({
    url: '/cdwwechatapi/wechatPublic/payment',
    method: 'post',
    data,
  });
}

/**
 *  个人中心
 *  积分记录
 */

export function getPointDetail(data) {
  return request({
    url: '/cdwwechatapi/wechatPublic/getPointDetail',
    method: 'post',
    data,
  });
}

/**
 *  个人中心
 *  解绑用水户
 */

export function unbundlingWaterAcount(params) {
  return request({
    url: '/cdwwechatapi/wechatPublic/unbundlingWaterAcount',
    method: 'get',
    params,
  });
}
