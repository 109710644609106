import cookies from 'js-cookie';
import storage from '@/utils/storage';
const LoginStatusKey = 'Login-Status'; // 登录态 0未授权未登录 1授权未登录 2 登陆成功
const TokenKey = 'Access-Token'; // token
const UserInfoKey = 'User-Info'; // 用户信息 {} {...}
const sendStatus = 'send-status';
const homeInfo = 'homeInfo';
const businessNews = 'businessNews';
const pathUrl = 'pathUrl';

// 获取登录状态
export function loadLoginStatus() {
  return cookies.get(LoginStatusKey) || 0;
}
// 保持登录状态
export function saveLoginStatus(status) {
  cookies.set(LoginStatusKey, status, { expires: 7 });
  return status;
}
// 删除登录状态
export function removeLoginStatus() {
  cookies.remove(LoginStatusKey);
  return '';
}
// 获取token
export function loadToken() {
  return storage.get(TokenKey);
}
// 保存token
export function saveToken(token) {
  storage.set(TokenKey, token);
  return token;
}
// 删除token
export function removeToken() {
  storage.remove(TokenKey);
  return '';
}
// 获取用户信息
export function loadUserInfo() {
  return storage.get(UserInfoKey);
}
// 保存用户信息
export function saveUserInfo(userInfo) {
  storage.set(UserInfoKey, userInfo);
  return userInfo;
}
// 删除用户信息
export function removeUserInfo() {
  storage.remove(UserInfoKey);
  return {};
}
// ---获取首页信息---
export function loadHome(info) {
  storage.set(homeInfo, info);
  return info;
}
export function getHomeInfo() {
  return storage.get(homeInfo);
}
// 获取发送状态
export function getSendStatus() {
  storage.get(sendStatus, 0);
}
// 保存发送状态
export function saveSendStatus(status) {
  storage.set(sendStatus, status);
  return status;
}
// 保存发送状态
export function loadBusinessNews(info) {
  storage.set(businessNews, info);
  return info;
}
export function getBusinessNews() {
  return storage.get(businessNews);
}
// 保存路由
export function loadPathUrl(info) {
  storage.set(pathUrl, info);
  return info;
}
export function getPathUrl() {
  return storage.get(pathUrl)
}
