/*
 * @Author: your name
 * @Date: 2020-11-11 09:06:58
 * @LastEditTime: 2020-11-11 14:11:18
 * @LastEditors: Please set LastEditors
 * @Description: 工具类
 * @FilePath: \nethallofficialaccounts\src\utils\tool.js
 */

// 时间格式转换 2020-11-02 转换成 2020年11月02日
export function formatTime(value) {
    if (value) {
        let dateArr = value.split(" ");
        let date = new Date(dateArr[0]);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        month = month.lenght < 10 ? `0${month}` : month
        day = day.length < 10 ? `0${day}` : day;
        if (dateArr.length <= 1) {
            return `${year}年${month}月${day}日`
        } else {
            return `${year}年${month}月${day}日 ${dateArr[1]}`
        }
    } else {
        return ''
    }
}
